<template>
  <div>
    <check-verified-profile />
    <n-grid cols="1 s:10" responsive="screen" style="padding-top: 30px">
      <n-gi span="8" offset="0 s:1">
        <n-tabs type="segment">
          <n-tab-pane name="Renter" :tab="t('rent.tabs.renter.title')">
            <Table
              :data="dataAsRenter"
              :itemsActions="maintenanceItemActions"
              :fieldsToHide="fieldsToHide"
              :fieldsTooLong="fieldsTooLong"
              :filterableFields="filterableFields"
            /> </n-tab-pane
          ><n-tab-pane name="owner" :tab="t('rent.tabs.owner.title')">
            <Table
              :data="dataAsOwner"
              :itemsActions="maintenanceItemActions"
              :fieldsToHide="fieldsToHide"
              :fieldsTooLong="fieldsTooLong"
              :filterableFields="filterableFields" /></n-tab-pane
        ></n-tabs> </n-gi
    ></n-grid>
  </div>
</template>
<script>
import { defineComponent, computed, onMounted } from "vue";
import {
  EyeOutline as ViewIcon,
  ChatboxOutline as ChatIcon,
  CheckmarkCircleOutline as DeliveryCheckListIcon,
  CheckmarkDoneCircleOutline as ReturnCheckListIcon,
  CalendarOutline as ChangeDatesIcon,
} from "@vicons/ionicons5";
import { Money as PayIcon, Review as ReviewIcon } from "@vicons/carbon";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { NGrid, NGi, NTabs, NTabPane } from "naive-ui";
import Table from "@/components/TableBeta.vue";
import { formatDates } from "@/shared/utils";
import CheckVerifiedProfile from "@/components/myProfile/CheckVerifiedProfile.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    Table,
    NGrid,
    NGi,
    NTabs,
    NTabPane,
    CheckVerifiedProfile,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const router = useRouter();
    const store = useStore();
    const currentUser = store.getters["auth/user_id"];

    onMounted(async () => {
      await store.dispatch("app/lockUI");
      await store.dispatch("maintainer_rents/fillRents", currentUser);
      await store.dispatch("app/unlockUI");
    });

    const dataRef = computed(() => store.state.maintainer_rents.rents);

    const rentsRef = computed(() =>
      dataRef.value.map((rent) => {
        return {
          id: rent.id,
          [t("commons.labels.publication")]: rent.publication.title,
          [t("commons.labels.start")]: formatDates(rent.requestedRangeOfDates.start),
          [t("commons.labels.end")]: formatDates(rent.requestedRangeOfDates.end),
          [t("commons.labels.status")]: t(`rent.states.${rent.state}`),
          state: rent.state,
          publicationId: rent.publication.id,
          rangeOfDatesHistory: rent.rangeOfDatesHistory,
          requestedRangeOfDates: rent.requestedRangeOfDates,
          user: rent.user,
        };
      })
    );

    const fieldsToHideRef = [
      "id",
      "user",
      "publicationId",
      "rangeOfDatesHistory",
      "requestedRangeOfDates",
      "state",
    ];

    const fieldsTooLongRef = computed(() => [t("commons.labels.publication")]);

    const filterableFieldsRef = computed(() => [
      t("commons.labels.publication"),
      t("commons.labels.status"),
    ]);

    const canChangeDates = (rent) => {
      return rent.state === "IN_PROCESS" || rent.state === "PENDING";
    };

    return {
      t,
      maintenanceItemActions: (rent) => {
        let actions = [];
        // Action to go to rent details
        actions.push({
          label: t("commons.labels.detail", 1),
          key: "details",
          icon: ViewIcon,
          props: {
            onClick: () => {
              router.push({
                name: "view-rent-detail",
                params: { id: rent.id },
              });
            },
          },
        });
        if (
          rent.state === "PENDING" ||
          rent.state === "IN_PROCESS" ||
          rent.state === "PENDING_FOR_PAY_EXTRA_DAYS" ||
          rent.state === "PENDING_FOR_CHANGE_PAYMENT_METHOD" ||
          rent.state === "DEPOSIT_PENDING"
        ) {
          actions.push({
            label: t("commons.labels.chat"),
            key: "chat",
            icon: ChatIcon,
            props: {
              onClick: () => {
                router.push({
                  name: "rent-chat",
                  params: { id: rent.id },
                });
              },
            },
          });
        }

        // Action to go to rent checklist
        // If the rent is pending and the current user is the owner, goes to delivery checklist
        // If the rent is in process and the current is the owner, goes to the return checklist
        // If the rent is in process and the current is the renter, goes to see the delivery checklist registered
        if (rent.state === "PENDING" && currentUser !== rent.user) {
          actions.push({
            label: t("commons.actions.check", {
              reference: t("commons.labels.delivery"),
            }),
            key: "deliver",
            icon: DeliveryCheckListIcon,
            props: {
              onClick: () => {
                router.push({
                  name: "checklist",
                  params: { rentId: rent.id, checklistType: "DELIVERY" },
                });
              },
            },
          });
        }
        if (rent.state === "IN_PROCESS" || rent.state === "RETURNED") {
          actions.push({
            label:
              rent.state === "IN_PROCESS" && currentUser !== rent.user
                ? t("commons.actions.check", {
                    reference: t("commons.labels.return"),
                  })
                : t("commons.actions.registered", {
                    reference: t("commons.labels.checklist"),
                  }),
            key: "return",
            icon: ReturnCheckListIcon,
            props: {
              onClick: () => {
                router.push({
                  name: "checklist",
                  params: {
                    rentId: rent.id,
                    checklistType:
                      rent.user === currentUser && rent.state === "IN_PROCESS"
                        ? "DELIVERY"
                        : "RETURN",
                  },
                });
              },
            },
          });
        }
        if (currentUser === rent.user) {
          if (canChangeDates(rent)) {
            actions.push({
              label: t("commons.actions.change", {
                reference: t("commons.labels.date", 2),
              }),
              key: "add-days",
              icon: ChangeDatesIcon,
              props: {
                onClick: () => {
                  router.push({
                    name: "rent",
                    params: {
                      publicationId: rent.publicationId,
                      rentId: rent.id,
                    },
                  });
                },
              },
            });
          }

          if (
            rent.state === "PENDING_FOR_PAY_EXTRA_DAYS" ||
            rent.state === "PENDING_FOR_CHANGE_PAYMENT_METHOD" ||
            rent.state === "DEPOSIT_PENDING"
          ) {
            actions.push({
              label: t("commons.actions.pay"),
              key: "pay",
              icon: PayIcon,
              props: {
                onClick: () => {
                  router.push({
                    name: "rent",
                    params: {
                      publicationId: rent.publicationId,
                      rentId: rent.id,
                    },
                  });
                },
              },
            });
          }
        }

        if (rent.state === "FINISHED" || rent.state === "RETURNED") {
          actions.push({
            label: t("commons.labels.review", 1),
            key: "review",
            icon: ReviewIcon,
            props: {
              onClick: () => {
                router.push({
                  name: "review",
                  params: {
                    rentId: rent.id,
                  },
                });
              },
            },
          });
        }

        return actions;
      },
      dataAsRenter: computed(() =>
        rentsRef.value.filter((rent) => currentUser == rent.user)
      ),
      dataAsOwner: computed(() =>
        rentsRef.value.filter((rent) => currentUser !== rent.user)
      ),
      fieldsToHide: fieldsToHideRef,
      fieldsTooLong: fieldsTooLongRef,
      filterableFields: filterableFieldsRef,
    };
  },
});
</script>
